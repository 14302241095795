import { Suspense, useEffect } from "react";
import { useBuild } from "app/components/Playground/BuildContext";
import { isTerminalBuildState } from "app/constants/BuildStates";
import WaterfallChartRow from "../components/Waterfall/WaterfallChartRow";
import NotEnoughData from "../components/Waterfall/EmptyStates/NotEnoughData";
import UpgadeRequired from "../components/Waterfall/EmptyStates/UpgadeRequired";
import IncompatibleBuild from "../components/Waterfall/EmptyStates/IncompatibleBuild";
import { Build, WaterfallData } from "app/stores/BuildShowStore";
import {
  Await,
  defer,
  LoaderFunctionArgs,
  useLoaderData,
  useRevalidator,
} from "react-router-dom";
import Skeleton from "../components/Waterfall/EmptyStates/Skeleton";
import classNames from "classnames";
import { DrawerOutlet } from "../components/DrawerOutlet";

export function loader({ params }: LoaderFunctionArgs) {
  const { org, pipeline, number } = params;
  const waterfallDataUrl = `/${org}/${pipeline}/builds/${number}/waterfall_tab.json`;

  const response = fetch(waterfallDataUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": window._csrf.token,
      "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
    },
  }).then((res) => res.json());

  return defer({ waterfallData: response });
}

function MissingData({ build }: { build: Build }) {
  if (isTerminalBuildState(build.state)) {
    return <IncompatibleBuild />;
  }
  return <NotEnoughData />;
}

export default function WaterfallPage() {
  const data = useLoaderData() as { waterfallData: WaterfallData };
  const revalidator = useRevalidator();
  const { build, store } = useBuild();

  if (!store || !build) {
    throw new Error("Missing build context");
  }

  // Re-fetch the loader data when the build changes
  useEffect(() => {
    revalidator.revalidate();
  }, [build]);

  // Need to upgrade to see the waterfall
  if (!store.waterfallAvailable) {
    return <UpgadeRequired />;
  }

  return (
    <>
      <Suspense fallback={<Skeleton />}>
        <Await resolve={data.waterfallData}>
          {(waterfallData: WaterfallData) =>
            waterfallData.chart_data.length > 0 ? (
              <div className="flex-1">
                <section
                  className={classNames(
                    "relative w-full overflow-clip",
                    Features.BuildSidebar
                      ? ""
                      : "border border-gray-400 rounded-lg",
                  )}
                >
                  {waterfallData.chart_data.map((row, index) => {
                    const lastRow =
                      waterfallData.chart_data.length - 1 === index;

                    return (
                      <WaterfallChartRow
                        key={row.step_uuid}
                        isLastRow={lastRow}
                        data={row}
                        barContainerPadding={
                          waterfallData.bar_container_padding
                        }
                      />
                    );
                  })}
                </section>
              </div>
            ) : (
              <MissingData build={build} />
            )
          }
        </Await>
      </Suspense>
      <DrawerOutlet />
    </>
  );
}
