/* eslint-disable react/no-unused-prop-types */
import BuildShowStore, { Build, FaviconPaths } from "app/stores/BuildShowStore";
import {
  BuildContextProvider,
  useBuild,
} from "app/components/Playground/BuildContext";
import {
  RouterProvider,
  Navigate,
  RouteObject,
  useNavigate,
  createBrowserRouter,
  NonIndexRouteObject,
  useRouteError,
  useParams,
  isRouteErrorResponse,
} from "react-router-dom";

import { ErrorView } from "app/components/shared/ErrorView";
import BuildShowLayout from "./layouts/BuildShowLayout";
import StepDrawer from "./components/StepDrawer";
import JobsPage from "./pages";
import CanvasPage from "./pages/canvas";
import SummaryPage from "./pages/summary";
import WaterfallTurboPage from "./pages/waterfall-turbo";
import WaterfallPage, { loader as waterfallLoader } from "./pages/waterfall";
import TestDigestPage from "./pages/summary/test-digest";
import { parseHash } from "app/stores/BuildPageStore";
import Annotations from "./pages/summary/annotations";

export enum BuildView {
  Summary = "SUMMARY",
  JobList = "JOB_LIST",
  Waterfall = "WATERFALL",
  WaterfallTurbo = "WATERFALL_TURBO",
  Canvas = "CANVAS",
  TestDigest = "TEST_DIGEST",
}

export type StepRouteParams = {
  stepOrJobId: string;
};

const waterfallRoute: NonIndexRouteObject = Features.ReactWaterfall
  ? {
      path: "waterfall",
      id: BuildView.Waterfall,
      element: <WaterfallPage />,
      errorElement: <RouteError />,
      loader: waterfallLoader,
      children: [
        {
          path: ":stepOrJobId",
          element: <StepDrawer />,
          errorElement: <RouteError />,
        },
      ],
    }
  : {
      path: "waterfall",
      id: BuildView.WaterfallTurbo,
      element: <WaterfallTurboPage />,
      errorElement: <RouteError />,
    };

export const routes: RouteObject[] = Features.BuildSidebar
  ? [
      {
        path: ":org/:pipeline/builds/:number/",
        element: <BuildShowLayout />,
        errorElement: <RouteError />,
        children: [
          {
            index: true,
            Component: () => {
              let url = "./canvas";

              // If the URL has a hash, navigate to the step.
              const hash = parseHash(window.location.hash);
              if (hash && hash.job) {
                url += `/${hash.job}`;

                // Preserve the line number if it exists.
                if (hash.line && hash.group) {
                  url += `#/${hash.group}-${hash.line}`;
                } else if (hash.group) {
                  url += `#/${hash.group}`;
                }
              }

              return <Navigate to={url} replace={true} />;
            },
            errorElement: <RouteError />,
          },
          {
            path: "summary",
            id: BuildView.Summary,
            element: <SummaryPage />,
            errorElement: <RouteError />,
            children: [
              {
                index: true,
                element: <Navigate to="./annotations" replace={true} />,
              },
              {
                path: "annotations",
                element: <Annotations />,
                errorElement: <RouteError />,
              },
              {
                path: "test-digest",
                element: window.Features.AnalyticsPipelinesBuildHeaderTab ? (
                  <TestDigestPage />
                ) : (
                  <Navigate to=".." replace={true} />
                ),
                errorElement: <RouteError />,
              },
            ],
          },
          {
            path: "canvas",
            id: BuildView.Canvas,
            element: <CanvasPage />,
            errorElement: <RouteError />,
            children: [
              {
                path: ":stepOrJobId",
                element: <StepDrawer />,
              },
            ],
          },
          waterfallRoute,
        ],
      },
    ]
  : [
      {
        path: ":org/:pipeline/builds/:number/",
        element: <BuildShowLayout />,
        errorElement: <RouteError />,
        children: [
          {
            index: true,
            id: BuildView.JobList,
            element: <JobsPage />,
            errorElement: <RouteError />,
          },
          {
            path: "canvas",
            id: BuildView.Canvas,
            element: <CanvasPage />,
            errorElement: <RouteError />,
            children: [
              {
                path: ":stepOrJobId",
                Component: () => {
                  const { store } = useBuild();
                  const { stepOrJobId = "" } = useParams<StepRouteParams>();

                  if (!store?.steps.has(stepOrJobId)) {
                    return <Navigate to=".." replace={true} />;
                  }
                  return null;
                },
              },
            ],
          },
          waterfallRoute,
          {
            path: "test_digest",
            id: BuildView.TestDigest,
            element: <TestDigestPage />,
            errorElement: <RouteError />,
          },
        ],
      },
    ];

function RouteError() {
  const navigate = useNavigate();
  const routeError = useRouteError();

  // If the error is a 404, don't render an error message.
  //
  // This can occur when navigating to a non-existent route that's been pushed into history
  // by another client-side history action. (ie. turbo links)
  if (isRouteErrorResponse(routeError) && routeError.status === 404) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-bind
  return <ErrorView clearError={() => navigate(0)} />;
}

interface PageProps {
  build: Build;
  faviconPaths: FaviconPaths;
  waterfallAvailable: boolean;
}

export default (props: PageProps) => {
  const store = new BuildShowStore(props);
  const router = createBrowserRouter(routes);

  return (
    <BuildContextProvider store={store}>
      <RouterProvider router={router} />
    </BuildContextProvider>
  );
};
