import { useBuild } from "app/components/Playground/BuildContext";
import Badge from "app/components/shared/Badge";
import EmojiAvatar from "app/components/shared/EmojiAvatar";
import Emojify from "app/components/shared/Emojify";
import { PipelineSettings } from "./PipelineSettings";

export const PipelineInfo = () => {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }

  const { project } = build;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <EmojiAvatar emoji={project.emoji} color={project.color} />

        <div className="flex flex-column gap0.5 min-width-0">
          <div className="flex items-center gap-1">
            <a
              className="h3 line-height-3 black m0 semi-bold truncate hover:text-inherit hover:underline"
              data-testid="projectUrl"
              href={project.url}
            >
              <Emojify text={project.name} />
            </a>

            <PipelineSettings />

            {project.public && (
              <Badge outline={true} className="very-dark-grey m-0">
                Public
              </Badge>
            )}
          </div>

          {project.description && (
            <Emojify
              className="line-height-3 dark-gray m0 truncate"
              text={project.description}
            />
          )}
        </div>
      </div>
    </div>
  );
};
