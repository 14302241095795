import { useBuild } from "app/components/Playground/BuildContext";
import { getBuildColor } from "../lib/getBuildColor";

export const StatusBar = () => {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }

  const isRunning =
    build.state === "failing" ||
    build.state === "started" ||
    build.state === "canceling" ||
    (build.state === "blocked" && build.blockedState === "running");

  const runningStyles = isRunning
    ? {
        animation: "animation-running-bar_horizontal 1s infinite linear",
        backgroundImage:
          "linear-gradient(-45deg, rgba(255,255,255,.5) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.5) 50%, rgba(255,255,255,.5) 75%, transparent 75%, transparent)",
        backgroundSize: "12px 12px",
      }
    : {};

  return (
    <div
      className="h-1"
      style={{
        backgroundColor: getBuildColor(build).primaryColor,
        ...runningStyles,
      }}
    />
  );
};
