/* eslint-disable id-length */

import Icon from "app/components/shared/Icon";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";
import { useHotKey } from "app/components/Playground/Controls/useHotKey";
import {
  DockPosition,
  useBuildPreferencesStore,
} from "../lib/useBuildPreferencesStore";

interface Props {
  children: React.ReactNode;
  onClose: () => void;
}

const getVariants = (dock: DockPosition) => {
  switch (dock) {
    case DockPosition.Bottom:
      return {
        initial: { y: "100% " },
        animate: { y: 0 },
        exit: { y: "100%", transition: { duration: 0 } },
      };
    case DockPosition.Right:
      return {
        initial: { x: "100% " },
        animate: { x: 0 },
        exit: { x: "100%", transition: { duration: 0 } },
      };
  }
};

/**
 * A collapsible drawer
 */
export const Drawer = ({ children, onClose }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const height = useBuildPreferencesStore((state) => state.resizedDrawerHeight);
  const width = useBuildPreferencesStore((state) => state.resizedDrawerWidth);

  const setResizedDrawerHeight = useBuildPreferencesStore(
    (state) => state.setResizedDrawerHeight,
  );

  const setResizedDrawerWidth = useBuildPreferencesStore(
    (state) => state.setResizedDrawerWidth,
  );

  const dockPosition = useBuildPreferencesStore((state) => state.dockPosition);
  const setDockPosition = useBuildPreferencesStore(
    (state) => state.setDockPosition,
  );

  useHotKey("Escape", () => {
    if (expanded) {
      setExpanded(false);
    } else {
      onClose();
    }
  });

  const toggleDock = useCallback(() => {
    if (dockPosition === DockPosition.Bottom) {
      setDockPosition(DockPosition.Right);
    }

    if (dockPosition === DockPosition.Right) {
      setDockPosition(DockPosition.Bottom);
    }
  }, [dockPosition]);

  const toggleExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  /**
   * Resize the drawer when dragging the resize handle
   *
   * Hook into the mouse down event to start dragging the resize handle
   * and update the drawer size based on the mouse position, storing the
   * new size in a preferences store for persistence across sessions.
   *
   * Sizes are stored as percentages to allow for responsive resizing.
   */
  const handleDrag = useCallback(() => {
    const onMouseMove = (event: MouseEvent) => {
      // Prevent resizing when the drawer is expanded
      if (expanded) {
        return;
      }

      // If the drawer is docked to bottom, resize the height (as a percentage)
      if (dockPosition === DockPosition.Bottom) {
        let height =
          ((window.innerHeight - event.clientY) / window.innerHeight) * 100;

        if (height > 100) {
          height = 100;
        } else if (height < 5) {
          height = 5;
        }

        setResizedDrawerHeight(height);
      }

      // If the drawer is docked to right, resize the width (as a percentage)
      if (dockPosition === DockPosition.Right) {
        let width =
          ((window.innerWidth - event.clientX) / window.innerWidth) * 100;

        if (width > 100) {
          width = 100;
        } else if (width < 5) {
          width = 5;
        }

        setResizedDrawerWidth(width);
      }
    };
    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  }, [setResizedDrawerHeight, setResizedDrawerWidth, expanded]);

  const style: React.CSSProperties = {};
  if (dockPosition === DockPosition.Right) {
    style.width = expanded ? `100%` : `${width}%`;
  }

  if (dockPosition === DockPosition.Bottom) {
    style.height = expanded ? `100%` : `${height}%`;
  }

  return (
    <motion.div
      data-position={dockPosition}
      data-testid="drawer"
      style={style}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={getVariants(dockPosition)}
      transition={{ type: "spring", duration: 0.45 }}
      className={twMerge(
        // Custom class to override the some job list item styles (consolidate once this is the source of truth)
        "drawer group",

        "bg-white z-50 fixed rounded-md",
        classNames({
          "bottom-0 top-0 right-0": dockPosition === DockPosition.Right,
          "shadow-[-20px_0_25px_-5px_rgb(0_0_0_/_0.1)]":
            dockPosition === DockPosition.Right,
          "left-0": expanded && dockPosition === DockPosition.Right,

          "bottom-0 left-0 right-0": dockPosition === DockPosition.Bottom,
          "shadow-[0_-20px_25px_-5px_rgb(0_0_0_/_0.1)]":
            dockPosition === DockPosition.Bottom,
          "top-0": expanded && dockPosition === DockPosition.Bottom,
        }),
      )}
    >
      <div>
        {dockPosition === DockPosition.Bottom && (
          <div
            className="bg-transparent w-full h-2 absolute left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 cursor-ns-resize select-none"
            onMouseDown={handleDrag}
          />
        )}

        {dockPosition === DockPosition.Right && (
          <div
            className="bg-transparent h-full w-2 absolute top-1/2 -translate-y-1/2 -translate-x-1/2 z-20 cursor-ew-resize select-none"
            onMouseDown={handleDrag}
          />
        )}

        <div className="absolute h-full w-full left-0 top-0 overflow-auto">
          <div className="flex flex-col">
            <div className="flex gap-2 sticky top-0 z-10 bg-white py-2 px-4 justify-between">
              <DrawerButton onClick={toggleExpand}>
                {dockPosition === DockPosition.Right && (
                  <Icon
                    className="h-5"
                    icon={
                      expanded
                        ? "heroicons/20/solid/chevron-right"
                        : "heroicons/20/solid/chevron-left"
                    }
                  />
                )}

                {dockPosition === DockPosition.Bottom && (
                  <Icon
                    className="h-5"
                    icon={
                      expanded
                        ? "heroicons/20/solid/chevron-down"
                        : "heroicons/20/solid/chevron-up"
                    }
                  />
                )}
              </DrawerButton>

              <div className="flex gap-1">
                <DrawerButton
                  onClick={toggleDock}
                  data-testid="drawer-dock-position"
                >
                  {dockPosition === DockPosition.Bottom && (
                    <Icon icon="custom/outline/panel-right" className="h-4" />
                  )}
                  {dockPosition === DockPosition.Right && (
                    <Icon icon="custom/outline/panel-bottom" className="h-4" />
                  )}
                </DrawerButton>

                <DrawerButton
                  onClick={onClose}
                  data-testid="drawer-close-button"
                >
                  <Icon icon="heroicons/20/solid/x-mark" className="h-5" />
                </DrawerButton>
              </div>
            </div>
            <div className="px-4">{children}</div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const DrawerButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button
    {...props}
    className="rounded-md w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-purple-100 hover:text-purple-600 transition-colors duration-200 ease-in-out"
  />
);
