import { Step } from "app/lib/pipeline";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { StepLink } from "./StepLink";
import { Label } from "./Label";
import { StateIcon } from "app/components/Playground/Node/BaseNode/StateIcon";
import { textColorForStep } from "app/components/Playground/Node/colors";
import Emojify from "app/components/shared/Emojify";

/**
 * A catch-all step list item.
 */
export function StepListItem({
  step,
  onClick,
  className,
}: {
  step: Step;
  onClick?: () => void;
  className?: string;
}) {
  const { stepId } = useParams();

  return (
    <StepLink
      uuid={step.uuid}
      onClick={onClick}
      className={twMerge(
        "rounded-md hover:bg-gray-200 pl-2",
        classNames({ "bg-gray-200": step.uuid === stepId }),
        className,
      )}
    >
      <Label
        command={step.type === "command" ? step.commandScript : undefined}
        status={
          <StateIcon
            className={twMerge("w-7", textColorForStep(step))}
            {...step}
          />
        }
      >
        {step.label && <Emojify text={step.label} />}
      </Label>
    </StepLink>
  );
}
