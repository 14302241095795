import { WaterfallBarType, WaterfallRowType } from "app/stores/BuildShowStore";
import WaterfallRowLabel from "./WaterfallRowLabel";
import MaybeLink from "./MaybeLink";
import classNames from "classnames";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
} from "app/components/shared/HoverCard";

type WaterfallPopoverDurationProps = {
  duration: string;
  text: string;
  variant: "waiting" | "dispatching" | "running";
};

function WaterfallPopoverDuration(props: WaterfallPopoverDurationProps) {
  const circleColor: Record<WaterfallPopoverDurationProps["variant"], string> =
    {
      waiting: "var(--gray-500)",
      dispatching: "var(--orange-500)",
      running: "var(--green-500)",
    };

  return (
    <div className="flex items-center gap1 nowrap">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
      >
        <circle
          cx="5"
          cy="5"
          r="4"
          stroke={circleColor[props.variant]}
          strokeWidth="1.5"
        />
      </svg>
      <span>
        <span className="monospace">{props.duration}</span> {props.text}
      </span>
    </div>
  );
}

type WaterfallChartBarProps = WaterfallBarType & {
  isLastBar: boolean;
  duration: string | undefined;
};

function WaterfallChartBar(props: WaterfallChartBarProps) {
  const groupParent =
    props.waiting_percentage === 0 &&
    props.running_percentage === 0 &&
    props.dispatching_percentage === 0 &&
    props.percentage_of_total > 0;

  const outcomeBarColor = props.passed ? "var(--green-500)" : "var(--red-500)";

  return (
    <>
      {groupParent ? (
        <HoverCard openDelay={100} closeDelay={50}>
          <HoverCardTrigger asChild={true}>
            <div
              data-testid="waterfall-chart-bar"
              style={{
                left: `${props.bar_start_percentage}%`,
                top: "calc(50% - 5px)",
                width: `${props.percentage_of_total}%`,
                backgroundColor: outcomeBarColor,
              }}
              className="absolute waterfall-chart__bar rounded-sm h-2.5"
            />
          </HoverCardTrigger>
          <HoverCardContent size="max-lg">
            <div className="charcoal-800">
              <div>
                <span className="monospace">{props.total_bar_duration}</span>{" "}
                Total
              </div>
            </div>
          </HoverCardContent>
        </HoverCard>
      ) : (
        <HoverCard openDelay={100} closeDelay={50}>
          <HoverCardTrigger asChild={true}>
            <div
              data-testid="waterfall-chart-bar"
              style={{
                left: `${props.bar_start_percentage}%`,
                top: "calc(50% - 5px)",
                width: `${props.percentage_of_total}%`,
                height: "10px",
              }}
              className="absolute waterfall-chart__bar"
            >
              <div className="flex overflow-hidden w-full h-full rounded-sm">
                <div
                  className="h-full bg-gray-500"
                  style={{
                    width: `${props.waiting_percentage}%`,
                  }}
                />
                <div
                  className="h-full bg-orange-500"
                  style={{
                    width: `${props.dispatching_percentage}%`,
                  }}
                />
                <div
                  className="h-full"
                  style={{
                    width: `${props.running_percentage}%`,
                    backgroundColor: outcomeBarColor,
                  }}
                />
              </div>
            </div>
          </HoverCardTrigger>
          <HoverCardContent size="max-lg">
            <div className="charcoal-800">
              <WaterfallPopoverDuration
                text="Waiting"
                duration={props.waiting_duration}
                variant="waiting"
              />
              <WaterfallPopoverDuration
                text="Dispatching"
                duration={props.dispatching_duration}
                variant="dispatching"
              />
              <WaterfallPopoverDuration
                text="Running"
                duration={props.running_duration}
                variant="running"
              />
            </div>
          </HoverCardContent>
        </HoverCard>
      )}

      {props.isLastBar && (
        <div
          data-testid="row-duration"
          className="absolute dark-gray monospace nowrap h6 regular m0"
          style={{
            left: `calc(${props.bar_start_percentage + props.percentage_of_total}% + 5px)`,
            top: "calc(50% - 8px)",
          }}
        >
          {props.duration}
        </div>
      )}
    </>
  );
}

export type WaterfallChartRowProps = {
  data: WaterfallRowType;
  isLastRow: boolean;
  depth?: number;
  barContainerPadding: number;
  parentTotalDuration?: string;
};

export default function WaterfallChartRow(props: WaterfallChartRowProps) {
  const { data, barContainerPadding, depth = 0, isLastRow } = props;

  // Because reverse mutates the array, we need to destructure
  // it to get a reversed array without mutating the original array
  const lastValidBar = [...data.bars]
    .reverse()
    .find((bar) => bar.percentage_of_total > 0);

  const isLastDescendant = isLastRow && data.children.length === 0;

  return (
    <>
      <div data-testid="waterfall-chart-row" className="flex items-center">
        <div className="bg-silver col-3 flex-none bg-silver border-r border-gray flex">
          {depth > 0 && <div style={{ width: `calc(1rem * ${depth})` }} />}
          <WaterfallRowLabel
            depth={depth}
            data={data}
            isLastDescendant={isLastDescendant}
          />
        </div>

        <div
          className={classNames(
            "w-full pl-[5px] self-stretch flex border-gray",
            isLastDescendant ? "" : "border-b",
          )}
          style={{ paddingRight: barContainerPadding }}
        >
          <div className="w-full relative">
            {data.bars.map((bar, index) => (
              <MaybeLink to={bar.job_url} key={["bar", bar.job_url].join("-")}>
                <WaterfallChartBar
                  {...bar}
                  isLastBar={lastValidBar === bar}
                  duration={
                    index === 0 ? bar.total_bar_duration : data.duration
                  }
                />
              </MaybeLink>
            ))}
          </div>
        </div>
      </div>
      {data.children.map((childData, childIndex) => {
        return (
          <WaterfallChartRow
            key={childData.job_uuid}
            data={childData}
            depth={depth + 1}
            isLastRow={childIndex === data.children.length - 1}
            parentTotalDuration={lastValidBar?.total_bar_duration}
            barContainerPadding={barContainerPadding}
          />
        );
      })}
    </>
  );
}
