import debounce from "lodash/debounce";
import { v4 as uuid } from "uuid";

import { type BuildStates } from "app/constants/BuildStates";

import cable from "app/lib/cable";
import Database from "app/lib/Database";
import Favicon from "app/lib/Favicon";
import performanceNow from "app/lib/performanceNow";
import { Step } from "app/lib/pipeline";

import BaseStore from "app/stores/BaseStore";
import { Job } from "app/components/build/Header/pipeline/types/Job";

type FaviconKeys = "failed" | "passed" | "started" | "scheduled";
export type FaviconPaths = Partial<Record<FaviconKeys, string>>;

export { type Job };

export interface Build {
  triggeredFrom?: {
    uuid: string;
    url: string;
    name: string;
    project: {
      name: string;
    };
    build: {
      number: number;
    };
  };
  pipelineSchedule?: {
    url: string;
    label: string;
  };
  creatorVerified: boolean;
  cancelPath: string;
  canRetryFailedJobs: boolean;
  nextBranchBuild?: {
    url: string;
    number: number;
    state: BuildStates;
  };
  prevBranchBuild?: {
    url: string;
    number: number;
    state: BuildStates;
  };
  authorName?: string;
  authorAvatar?: string;
  id: string;
  jsonPath: string;
  message?: string;
  number: number;
  account: {
    slug: string;
    name: string;
    jobMinuteLimitReached: boolean;
  };
  project: {
    id: string;
    name: string;
    slug: string;
    description?: string;
    url: string;
    public: boolean;
    archived: boolean;
    provider: {
      id: string;
      url: string;
      frontendIcon: string;
    };
    exampleRepository: boolean;
    emoji?: string;
    color?: string;
    allowRebuilds: boolean;
  };
  steps: Step[];
  jobs: Job[];
  state: BuildStates;
  cancelReason?: string;
  blockedState?: string;
  createdAt: string;
  canceledAt?: string;
  cancelledBy?: {
    name: string;
    avatar: string;
  };
  startedAt?: string;
  scheduledAt?: string;
  finishedAt?: string;
  source: string;
  pullRequest?: {
    id: string;
    url: string;
  };
  branchName: string;
  branchPath: string;
  commitId: string;
  commitUrl?: string;
  commitShortLength: number;
  jobsCount: number;
  totalJobDuration: number;
  annotationCountsByStyle: {
    [key: string]: number;
  };
  path: string;
  dispatchPausedClusterQueues: {
    key: string;
    path: string;
    dispatchPausedAt: string;
    dispatchPausedBy: {
      name: string;
      avatar: {
        url: string;
      };
    };
    dispatchPausedNote?: string | null;
  }[];
  duration?: {
    total: number;
    running: number;
    runningRetries: number;
    idle: number;
  };
  retry_counts?: {
    total: number;
    manual: number;
    automatic: number;
  };
  jobsRunning?: boolean;
  jobsIdle?: boolean;
  retriesRunning?: boolean;
  hasTestAnalytics?: boolean;
  permissions: {
    retry: {
      allowed: boolean;
      reason?: string;
      message?: string;
    };
    rebuild: {
      allowed: boolean;
      reason?: string;
      message?: string;
    };
    cancel: {
      allowed: boolean;
      reason?: string;
      message?: string;
    };
  };
  rebuildPath: string;
  rebuildBranchPath: string;
  newPath: string;
}

export type WaterfallBarType = {
  percentage_of_total: number;
  bar_start_percentage: number;
  waiting_percentage: number;
  dispatching_percentage: number;
  running_percentage: number;
  total_bar_duration: string;
  waiting_duration: string;
  dispatching_duration: string;
  running_duration: string;
  passed: boolean;
  job_url?: string | null;
};

export type WaterfallRowType = {
  bars: WaterfallBarType[];
  children: WaterfallRowType[];
  end_time: null | string;
  job_uuid?: string;
  step_uuid: string;
  job_url?: string | null;
  label: { text: string; format: "emojify" | "code" | "raw" };
  start_time: null | string;
  duration: string;
} & (
  | { parallel_group_index: number; parallel_group_total: number }
  | undefined
);

export type WaterfallData = {
  chart_data: WaterfallRowType[];
  bar_container_padding: number;
};

export default class BuildShowStore extends BaseStore {
  uuid: string = uuid();
  oldestReloadRequestTime: number | null | undefined = null;
  reloadInProgress = false;

  // A map of step UUIDs to step objects for easy lookup
  steps = new Map<string, Step>();

  // A map of job IDs to job objects for easy lookup
  jobs = new Map<string, Job>();

  build: Build;
  faviconPaths: FaviconPaths;
  waterfallAvailable: boolean;

  constructor({
    build: rawBuild,
    faviconPaths,
    waterfallAvailable = false,
  }: {
    build: Build;
    faviconPaths: FaviconPaths;
    waterfallAvailable: boolean;
  }) {
    // Call super on the parent store
    super();

    const build: Build = Database.parse(rawBuild);
    this.build = build;
    this.steps = new Map(build.steps.map((step) => [step.uuid, step]));
    this.jobs = new Map(build.jobs.map((job) => [job.id, job]));

    this.faviconPaths = faviconPaths;
    this.waterfallAvailable = waterfallAvailable;

    // When we receive a build update, update the build and emit an event.
    // @ts-expect-error - TS2339 - Property 'buildSubscription' does not exist on type 'BuildShowStore'.
    this.buildSubscription = cable.subscriptions.create(
      { channel: "Pipelines::BuildChannel", uuid: this.build.id },
      {
        store: this,
        number: this.build.number,

        received({ event }) {
          if (
            // Generically changed
            event === "updated" ||
            // Build state transitions which change appearance
            event === "started" ||
            event === "finished" ||
            event === "skipped" ||
            event === "canceling" ||
            // Happens when the commit is resolved from HEAD to a sha
            event === "commit:changed" ||
            // The steps have changed, maybe a pipeline upload or a step has
            // changed state, and we might need to re-render the build pipeline
            event === "steps:changed" ||
            // An annotation has been created, updated or removed
            event === "annotations:changed"
          ) {
            this.store.reload({
              source: this.store.uuid,
              trigger: `event:${event}`,
            });
          }
        },
      },
    );

    this._updateFavicon();

    // Kick off an initial reload
    this.reload({ source: this.uuid, trigger: "initial" });
  }

  getBuild() {
    return this.build;
  }

  setBuild(build: Build) {
    this.build = build;
    this.steps = new Map(build.steps.map((step) => [step.uuid, step]));
    this.jobs = new Map(build.jobs.map((job) => [job.id, job]));

    this.emit("change");
    this._updateFavicon();
  }

  loadAndEmit(build: Build) {
    this.setBuild(Database.parse(build));
  }

  reload(data: any | null = {}) {
    if (this.oldestReloadRequestTime == null) {
      this.oldestReloadRequestTime = performanceNow();
    }

    this._debouncedReload(data);
  }

  // Each reload attempt defers the actual function by 1000 ms
  // A continuous stream of attempts would mean the function is never called.
  // maxWait puts an upper bound of 4000 ms on that.
  _debouncedReload = debounce(
    (data: any | null = {}) => {
      this._serialReload(data);
    },
    1000,
    { maxWait: 4000 },
  );

  _serialReload(data: any | null = {}) {
    if (this.reloadInProgress) {
      // A reload request is still in progress, so we need to defer this reload
      // again - we can do so by sending it back to the debounced method:
      this._debouncedReload(data);
    } else {
      this.reloadInProgress = true;

      this._performReload(data).always(() => {
        this.reloadInProgress = false;
      });
    }
  }

  _performReload(data: any | null = {}) {
    let delay = 0;
    if (this.oldestReloadRequestTime != null) {
      // Compute the number of milliseconds that have passed since the oldest
      // attempt to trigger a reload was made. This way we can tell how much
      // delay there was between an event that should trigger a reload and the
      // reload actually being attempted.
      //
      // This is just the delay to trigger the reload, the total delay perceived
      // by the user will be this plus the time it takes for the reload request
      // to complete.
      delay = Math.round(
        performanceNow() - (this.oldestReloadRequestTime || 0),
      );
    }

    const params = { ...data, delay } as const;

    // Clear oldest reload request time, so that the next reload attempt will
    // set it:
    this.oldestReloadRequestTime = null;

    return jQuery.ajax({
      url: this.build.jsonPath,
      data: params,
      headers: {
        "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
      } as {
        [key: string]: string;
      },
      success: (build) => {
        this.setBuild(Database.parse(build) as Build);
      },
    });
  }

  _updateFavicon() {
    const iconPath =
      this.faviconPaths[
        this._faviconKey(this.build.state, this.build.blockedState)
      ];
    if (iconPath != null) {
      Favicon.update(iconPath);
    }
  }

  _faviconKey(state: string, blockedState?: string | null): FaviconKeys {
    switch (state) {
      case "failed":
        return "failed";

      case "failing":
        return "failed";

      case "canceled":
        return "failed";

      case "canceling":
        return "failed";

      case "not_run":
        return "failed";

      case "blocked":
        switch (blockedState) {
          case "failed":
            return "failed";
          case "running":
            return "started";
          case "passed":
          default:
            return "passed";
        }
      case "passed":
        return "passed";

      case "started":
        return "started";

      default:
        return "scheduled";
    }
  }
}
