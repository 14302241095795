import { CommandJob } from "app/components/build/Header/pipeline/types/CommandJob";
import { useJobsForStep } from "app/components/Playground/BuildContext";
import { isParallelCommandStep, Step } from "app/lib/pipeline";
import { PropsWithChildren } from "react";
import { StepListItem } from "./StepListItem";
import { CollapsibleStepListItem } from "./CollapsibleStepListItem";
import { StepLink } from "./StepLink";
import { JobLabel } from "./JobLabel";
import { filterJobs, useFilterStore } from "../useFilterStore";

/**
 * An command step list item.
 *
 * Rendered as a single list item unless there are multiple jobs for the step.
 */
export function CommandStepListItem({
  step,
}: PropsWithChildren<{ step: Step }>) {
  const stateFilter = useFilterStore((state) => state.filterBy);

  let jobs = useJobsForStep<CommandJob>(step.uuid);
  jobs = filterJobs(jobs, stateFilter);

  // If there is only one job, and it's not a parallel command step, render a single step list item.
  if (jobs.length <= 1 && !isParallelCommandStep(step)) {
    return <StepListItem step={step} />;
  }

  return (
    <CollapsibleStepListItem step={step}>
      {jobs?.map((job) => (
        <div key={job.id} className="flex flex-auto pl-1.5">
          <StepLink uuid={job.id} className="pl-2">
            <JobLabel job={job} />
          </StepLink>
        </div>
      ))}
    </CollapsibleStepListItem>
  );
}
