import { useState, useCallback } from "react";
import useFetch from "use-http";
import _get from "lodash/get";
import useCollapse from "react-collapsed";
import Skeleton from "react-loading-skeleton";

import Icon from "app/components/shared/Icon";
import { Execution } from "app/components/analytics/shared/type";

type Props = {
  endpoint: string;
  failureReason: Execution["failureReason"];
  failureExpanded?: Execution["failureExpanded"];
};

const FailureReason = ({ endpoint, failureReason, failureExpanded }: Props) => {
  if (!failureReason) {
    return null;
  }

  const { get, response, loading, error } = useFetch(endpoint);
  const [expandedFailureReason, setExpandedFailureReason] =
    useState<Execution["failureExpanded"]>(failureExpanded);
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const fetchExpandedFailureReason = useCallback(async () => {
    if (expandedFailureReason) {
      return;
    }

    const failureExpanded = await get();
    if (response.ok) {
      setExpandedFailureReason(failureExpanded);
    }
  }, [expandedFailureReason]);

  return (
    <div className="bg-charcoal-700 relative rounded">
      <pre className="text-gray-500 font-mono text-xs font-normal leading-5 whitespace-pre-wrap overflow-hidden block break-words">
        <div
          className={`${
            !isExpanded ? "truncate" : ""
          } relative py-2 px-4 pl-12`}
        >
          <button
            className="no-title-tooltip cursor-pointer bg-none border-none transition ease-in-out duration-200 filter hover:brightness-150 absolute inset-0 w-full text-left pl-5"
            aria-label={
              isExpanded ? "Hide failure reason" : "Show failure reason"
            }
            {...getToggleProps({ onClick: fetchExpandedFailureReason })}
          >
            <Icon
              icon={isExpanded ? "thick-minus" : "thick-plus"}
              className="absolute top-3"
              style={{ width: "10px", height: "10px" }}
            />
          </button>

          {failureReason}
        </div>

        <div data-testid="expanded-failure-reason" {...getCollapseProps()}>
          <div className="mt-2 py-2 px-4 pl-12">
            {expandedFailureReason &&
              expandedFailureReason
                .map((failure) => {
                  const expandedString = _get(failure, "expanded", []).join(
                    "\n",
                  );
                  const backtraceString = _get(failure, "backtrace", []).join(
                    "\n",
                  );
                  return expandedString.concat("\n", backtraceString);
                })
                .join("\n\n")}
            {loading && (
              <Skeleton
                containerTestId="loading-skeleton"
                width="80%"
                baseColor="#444444"
                highlightColor="#5D5D5D"
                count={6}
              />
            )}
            {error && "Error: test failure reasons could not be loaded"}
          </div>
        </div>
      </pre>
    </div>
  );
};

export default FailureReason;
