import {
  findJobsForStep,
  mostRecentParallelJobs,
  useBuild,
} from "app/components/Playground/BuildContext";
import {
  useParams,
  useNavigate,
  Navigate,
  useResolvedPath,
} from "react-router-dom";
import { Drawer } from "../Drawer";
import { useCallback, useEffect } from "react";
import { ErrorBoundary } from "app/lib/Bugsnag";
import { ErrorView } from "app/components/shared/ErrorView";
import BuildPageStore from "app/stores/BuildPageStore";
import { isFailed, isParallelCommandStep, Step } from "app/lib/pipeline";
import Job from "app/components/job/Job";
import { CommandJob } from "app/components/build/Header/pipeline/types/CommandJob";
import { StepRouteParams } from "../..";
import { Build } from "app/stores/BuildShowStore";
import { TriggerJobDetails } from "./TriggerJobDetails";

export const StepDrawer = () => {
  const navigate = useNavigate();
  const { build, store } = useBuild();
  const { stepOrJobId = "" } = useParams<StepRouteParams>();
  const viewPath = useResolvedPath("..");

  if (!build || !store) {
    throw new Error("Missing build context");
  }

  // Close the drawer and navigate back to the build view (ie. waterfall / canvas / summary).
  const handleClose = useCallback(() => {
    navigate(viewPath);
  }, [viewPath]);

  // The trigger/root step isn't a real step in the pipeline so for now we
  // just ignore it.
  if (stepOrJobId === "root") {
    return null;
  }

  let job: Job | null;

  // If the stepId is a job, render the job details.
  job = store.jobs.get(stepOrJobId);
  if (job?.type === "script") {
    return (
      <Drawer onClose={handleClose}>
        <ErrorBoundary FallbackComponent={ErrorView}>
          <CommandJobDetails job={job} />
        </ErrorBoundary>
      </Drawer>
    );
  }

  // Redirect for unknown steps.
  const step = store.steps.get(stepOrJobId);
  if (!step) {
    return <Navigate to={`${build.path}`} />;
  }

  if (step.type === "trigger") {
    return (
      <Drawer onClose={handleClose}>
        <ErrorBoundary FallbackComponent={ErrorView}>
          <TriggerJobDetails step={step} />
        </ErrorBoundary>
      </Drawer>
    );
  }

  // Only render the drawer for command steps.
  if (step.type !== "command") {
    return null;
  }

  // If the step is a parallel command step and has failed, redirect to the first failure.
  if (isParallelCommandStep(step)) {
    if (!isFailed(step)) {
      return null;
    }

    const job = findFirstMostRecentFailureForParallelStep(step, build);
    return <Navigate to={`${viewPath.pathname}/${job.id}`} />;
  }

  job = findMostRecentJob(step, build);

  return (
    <Drawer onClose={handleClose}>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <CommandJobDetails job={job as CommandJob} />
      </ErrorBoundary>
    </Drawer>
  );
};

const CommandJobDetails = ({ job }: { job: CommandJob }) => {
  const { build, store } = useBuild();
  if (!build || !store) {
    return null;
  }

  useEffect(() => {
    BuildPageStore.expandJob(job);
  }, [job?.id]);

  return (
    <div className="job-list-pipeline">
      <Job
        key={job.id}
        job={job}
        build={build}
        buildStore={store}
        autoFollow={true}
      />
    </div>
  );
};

function findFirstMostRecentFailureForParallelStep(step: Step, build: Build) {
  let jobs = findJobsForStep<CommandJob>(build, step.uuid).filter(
    (job) => job.state === "finished" && !job.passed,
  );

  jobs = mostRecentParallelJobs(jobs);
  return jobs[0];
}

function findMostRecentJob(step: Step, build: Build) {
  const jobs = findJobsForStep(build, step.uuid);
  return jobs[jobs.length - 1];
}
