import { useBuild } from "app/components/Playground/BuildContext";
import { useMemo } from "react";
import capitalize from "lodash/capitalize";

import Badge from "app/components/shared/Badge";
import { GroupStep, Step } from "app/lib/pipeline";
import EmptyState from "./EmptyState";
import { createStepListItem } from "./StepListItem";
import {
  groupStepsBy,
  filterSteps,
  useFilterStore,
  StateOrder,
} from "./useFilterStore";

// Create nested steps by grouping steps by their group UUID
function createNestedSteps(steps: Step[]) {
  const stepsMap = new Map(steps.map((step) => [step.uuid, { ...step }]));

  stepsMap.forEach((step) => {
    if (step.groupUuid) {
      const group = stepsMap.get(step.groupUuid) as GroupStep;
      if (group && step.type !== "group") {
        if (!group.steps) {
          group.steps = [];
        }
        group.steps.push(step);
      }
      stepsMap.delete(step.uuid);
    }
  });

  return Array.from(stepsMap.values());
}

/**
 * Render a list of build steps.
 */
export function StepList() {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }

  const filterBy = useFilterStore((state) => state.filterBy);
  const groupOption = useFilterStore((state) => state.groupOption);

  // Apply filters then group steps by group uuid
  const filteredSteps = useMemo(() => {
    return createNestedSteps(filterSteps(build.steps, filterBy, build));
  }, [build.steps, filterBy]);

  const groupedSteps = useMemo(() => {
    return groupStepsBy(filteredSteps, groupOption);
  }, [filteredSteps, groupOption]);

  if (filteredSteps.length === 0) {
    return <EmptyState />;
  }

  return (
    <div>
      {groupedSteps ? (
        <div className="flex flex-column gap-5">
          {StateOrder.map((state) => {
            const steps = groupedSteps[state];
            if (!steps) {
              return null;
            }

            return (
              <section key={state} data-testid={`${state}-group`}>
                <h2 className="my-1">
                  <Badge className="bg-purple-100 text-purple-700 text-[13px] py-0.5 px-2 m-0">
                    <code className="monospace">{capitalize(state)}</code>
                  </Badge>
                </h2>
                {steps.map((step) => createStepListItem({ step }))}
              </section>
            );
          })}
        </div>
      ) : (
        filteredSteps.map((step) => createStepListItem({ step }))
      )}
    </div>
  );
}
