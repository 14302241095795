/* eslint-disable react/jsx-no-bind */
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBuild } from "app/components/Playground/BuildContext";
import {
  DockPosition,
  useBuildPreferencesStore,
} from "../../lib/useBuildPreferencesStore";
import { useFilterStore, FilterByOption } from "./useFilterStore";
import GroupByDropdown from "./GroupByDropdown";
import FilterByDropdown from "./FilterByDropdown";
import { StepList } from "./StepList";
import { StepRouteParams } from "../..";
import { AnnotationsLink } from "./AnnotationsLink";

export default function Sidebar() {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }

  const { stepOrJobId } = useParams<StepRouteParams>();
  const dockPosition = useBuildPreferencesStore((state) => state.dockPosition);
  const drawerHeight =
    useBuildPreferencesStore((state) => state.resizedDrawerHeight) || 0;

  const setFilterBy = useFilterStore((state) => state.setFilterBy);
  useEffect(() => {
    // If the build has failed, default the view to show failed steps
    if (build.state === "failed" || build.state === "failing") {
      setFilterBy([FilterByOption.Failed]);
    }
  }, [build.state]);

  return (
    <div className="flex flex-col gap-2 h-full" data-testid="sidebar">
      <div className="flex gap-1 w-full items-center justify-between flex-wrap sticky top-0 bg-white pt-2">
        <div className="flex flex-auto gap-1 flex-wrap">
          <FilterByDropdown />
          <GroupByDropdown />
        </div>
      </div>

      <div
        className="flex flex-col gap-3"
        style={
          // If the drawer is docked to the bottom, add padding to ensure the content
          // is always scrollable.
          stepOrJobId && dockPosition === DockPosition.Bottom
            ? {
                paddingBottom: `${window.innerHeight * (drawerHeight / 100)}px`,
              }
            : {}
        }
      >
        <StepList />

        <div className="sticky bottom-0">
          <AnnotationsLink />
        </div>
      </div>
    </div>
  );
}
