import { PropsWithChildren, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import Icon from "app/components/shared/Icon";
import { Outcome, State, Step } from "app/lib/pipeline";
import { StepListItem } from "./StepListItem";
import { StepRouteParams } from "../../..";

export function CollapsibleStepListItem({
  step,
  children,
}: PropsWithChildren<{ step: Step }>) {
  const { stepOrJobId } = useParams<StepRouteParams>();

  const [value, setValue] = useState<string[]>(
    step.state === State.Finished && step.outcome === Outcome.HardFailed
      ? [step.uuid]
      : [],
  );

  const onValueChange = useCallback((value: string[]) => {
    setValue(value);
  }, []);

  const open = useCallback(() => {
    setValue([step.uuid]);
  }, []);

  return (
    <Accordion.Root
      type="multiple"
      className="flex flex-auto"
      onValueChange={onValueChange}
      value={value}
    >
      <Accordion.Item value={step.uuid} className="w-full overflow-hidden">
        <Accordion.Trigger
          className={classNames(
            "flex w-full items-baseline rounded-md hover:bg-gray-200 [&>div>svg]:data-[state=open]:rotate-90",
            {
              "bg-gray-200": step.uuid === stepOrJobId,
            },
          )}
        >
          <div className="self-stretch shrink-0 flex items-center justify-center w-7 hover:bg-gray-400 rounded-md">
            <Icon
              icon="heroicons/outline/chevron-right"
              className="w-3 h-3 transition-transform duration-150"
            />
          </div>
          <div className="flex flex-auto">
            <StepListItem step={step} onClick={open} className="pl-0" />
          </div>
        </Accordion.Trigger>
        <Accordion.Content className="data-[state=open]:animate-[radixAccordionSlideDown_150ms_ease-in-out] data-[state=closed]:animate-[radixAccordionSlideUp_150ms_ease-in-out]">
          <div className="flex flex-col border-l border-gray-400 ml-3">
            {children}
          </div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
}
