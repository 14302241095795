import { WaterfallRowType } from "app/stores/BuildShowStore";
import classNames from "classnames";
import Emojify from "app/components/shared/Emojify";
import MaybeLink from "./MaybeLink";
import Badge from "app/components/shared/Badge";
import { useBuild } from "app/components/Playground/BuildContext";

type TextFormatter = Record<
  WaterfallRowType["label"]["format"],
  (text: string) => React.ReactNode
>;

const textFormatter: TextFormatter = {
  emojify: (text) => <Emojify title={text} text={text} />,
  raw: (text) => <span title={text}>text</span>,
  code: (text) => (
    <code title={text} className="monospace">
      {text}
    </code>
  ),
};

type WaterfallRowLabelProps = {
  data: WaterfallRowType;
  isLastDescendant: boolean;
  depth: number;
};

function useJobUrl(data: WaterfallRowType) {
  const { build } = useBuild();
  if (!build) {
    throw "Missing build context";
  }

  if (Features.BuildSidebar) {
    if (data.job_uuid) {
      return `${build.path}/waterfall/${data.job_uuid}`;
    }
    return null;
  }

  return data.job_url;
}

export default function WaterfallRowLabel(props: WaterfallRowLabelProps) {
  const jobUrl = useJobUrl(props.data);

  return (
    <MaybeLink
      data-testid="waterfall-job-link"
      to={jobUrl}
      className={classNames(
        props.depth > 0 && "bg-white border-l",
        props.isLastDescendant ? "" : "border-b",
        "flex-1 line-height-4 h-[52px] px-[15px] truncate border-gray text-decoration-none block",
      )}
    >
      <div className="flex items-center w-full h-full">
        <span
          className={classNames(props.depth > 0 ? "medium" : "semi-bold")}
          data-testid="row-label"
        >
          {props.data.parallel_group_index &&
            props.data.parallel_group_total && (
              <Badge outline={true} className="charcoal-500 mr-1">
                {props.data.parallel_group_index}/
                {props.data.parallel_group_total}
              </Badge>
            )}
          {textFormatter[props.data.label.format](props.data.label.text)}
        </span>
      </div>
    </MaybeLink>
  );
}
