import { Link } from "react-router-dom";
import { useMemo } from "react";
import { useBuild } from "app/components/Playground/BuildContext";
import Button from "app/components/shared/Button";
import Badge from "app/components/shared/Badge";

export const AnnotationsLink = () => {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }

  const annotationsCount = useMemo(() => {
    const error = build.annotationCountsByStyle.error || 0;
    const warning = build.annotationCountsByStyle.warning || 0;
    const totalCount = Object.values(build.annotationCountsByStyle).reduce(
      (acc, count) => acc + count,
      0,
    );

    return {
      error,
      warning,
      other: totalCount - warning - error,
    };
  }, [build.annotationCountsByStyle]);

  return (
    <div className="flex flex-column gap-1.5 pt-3 bg-white">
      <Link to={`${build.path}/summary/annotations`}>
        <Button className="btn-block btn-small">
          <span className="after:content-['→'] after:ml-1">Annotations</span>
        </Button>
      </Link>

      <div className="flex gap-3 justify-center">
        <div>
          Issues{" "}
          <Badge outline={true} className="ml-0">
            {annotationsCount.error}
          </Badge>
        </div>
        <div>
          Warnings{" "}
          <Badge outline={true} className="ml-0">
            {annotationsCount.warning}
          </Badge>
        </div>
        <div>
          Other{" "}
          <Badge outline={true} className="ml-0">
            {annotationsCount.other}
          </Badge>
        </div>
      </div>
    </div>
  );
};
